import Blog from '../pantallas/Casos_exito/Blog';
import About from '../pantallas/about/About';
import Footer from '../pantallas/Footer/Footer';
import Service from '../pantallas/Service';
import Feature from '../pantallas/Feature/Feature';
import Pricing from '../pantallas/Precio/Pricing';
import Download from '../pantallas/Download/Download';
import HeroOlive from '../pantallas/HeroOlive';
import React, {Component, Fragment} from "react";
import Testimonial from '../pantallas/Testimonios/Testimonial';
import Empresas from '../pantallas/Empresas_carrusel/empresas';
import Carrusel from "../pantallas/Soluciones_destacadas/carrusel";
import Desafios from "../pantallas/Desafios/desafios";
import Startups from "../pantallas/Startups/startups";
import DesafioDestacado from "../pantallas/Desafio_destacado/desafioDestacado.js";
/* import Planes from "../pantallas/Planes/planes"; */
import Navbar2 from "../pantallas/Navbar/Navbar2";
import Lanzar_llamado from "../pantallas/Lanzar_llamado/lanzar_llamado";
class HomeOlive extends Component {

    render() {
        return (
            <Fragment>

                <Navbar2 />
                <HeroOlive bgshape="bg-shape" horizontal=""/>

                {/* About */}
                <About horizontalabout="vertical-about"/>

                {/*Service  CONN ERROR al visualizar movil*/}
                <Service horizontal="vertical-service"/>
                {/*<Startups horizontal={false} />*/}

                {/*Feature */}
                <Feature horizontalfeature="vertical-service"/>

                <Lanzar_llamado />
                {/* <Planes/> */}
                {/*Startups */}
                <Startups horizontal={true}/>


                <DesafioDestacado />


                {/* Desafios */}
                <Desafios horizontal={false}/>

                {/* Desafios destacados */}
                <Carrusel/>

                {/* Pricing */}
                <Pricing horizontalpricing="vertical-pricing"/>

                {/* Pricing */}
                <Testimonial/>


                {/*/!* Screenshot *!/*/}
                {/*<Screenshot/>*/}

                {/* Blog */}
                <Blog/>

                {/* Download */}
                {/* <Download horizontal={false}/> */}


                {/* Empresas */}
                <Empresas/>

                {/* Footer */}
                <Footer horizontal="vertical-footer"/>
            </Fragment>
        )
    }
}

export default HomeOlive;

