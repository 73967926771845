import React from 'react';// import Login from "../Login/login";
import RegistrarCuenta from "../Registrar_cuenta/registrarCuenta";
import FormularioEmpresa from "../Formulario_empresas/formularioEmpresa";
import {Modal, ModalBody,Button} from "reactstrap";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {validarToken} from "../utils/token";


const Pricing = React.memo((props) => {
    const [idModal, setidModal] = React.useState(1);
    const [redirect, setredirect] = React.useState(false);
    const [modal, setmodal] = React.useState(false);
    const [colorBtn, setColorBtn] = React.useState(true);
    const {estadoLogin} = useSelector(state => state.estadoLogin);
    const {infoUser} = useSelector(state => state.usuarios);

    const handlePlataforma =  async () => {
        let token = localStorage.getItem('token_2')
        let token2 = localStorage.getItem('token_3')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
            email: localStorage.getItem('email')
        }, {headers: {authorization: token2}})
            .then((response) => {
                console.log(infoUser.diasvigencia)
                console.log(infoUser.state)
                if (infoUser.diasvigencia > 0 || infoUser.state === 1) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN VALIDO, REDIRECCIONANDO...')
                    }
                    window.open(url, '_self', 'noopener,noreferrer')
                } else {
                    setRedirect(true)
                }
            }).catch(error => {
                if( validarToken(error)) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN, INVALIDO DESLOGUEANDO...')
                    }
                }
                console.log('ERROR :', error.response.data.error)
            })
    }

    const handleModal = (idModal) => {
        console.log("apretado", modal)
        setidModal(idModal)
        setmodal(!modal)
    }
    const handlePrecio =(value) =>{
        console.log('aprete precio')
        if(value==='mensual'){
            setColorBtn(true)
        }else{
            setColorBtn(false)
        }
    }

    return (
        <div>
            <div className={`pricing-table-area  pt--190 mb--180"  ${props.horizontalpricing}`} id="planes">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40" data-aos="zoom-out-up" data-aos-delay="50"
                                 data-aos-duration="1000">
                                <h2><span className="theme-color">DESCUBRE OPORTUNIDADES</span> PARA TU NEGOCIO </h2>
                                {/*<img className="image-1" src={require('../assets/images/app/title-icon.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-2" src={require('../assets/images/app/title-icon-2.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-3" src={require('../assets/images/app/title-icon-3.png').default} alt="App Landing"/>*/}
                                {/*<p>There are many variations of passages of Lorem Ipsum available, but the majorityhave suffered alteration in some form, by injected humour,available</p>*/}
                            </div>
                        </div>
                    </div>

                    <div className="row mt--30">

                        {/* Start Single Pricing */}
                        <div className=" col-md-6 col-12 pricing-column mt--40" data-aos="fade-right"
                             data-aos-duration="2000">
                            <div className="single-price-package">
                                <div className="price-title">
                                    <p style={{fontSize: '1.5rem', color: '#ffff', fontWeight: 800}}>STARTUPS </p>
                                    <p></p>

                                </div>
                                <div className="price-list">
                                    <ul>
                                        <li>MUESTRA TU SOLUCIÓN</li>
                                        <li>MEJORA TU VISIBILIDAD</li>
                                        <li>ACTIVA OPORTUNIDADES DE NEGOCIO</li>
                                        <li>CONECTA ÁGILMENTE</li>
                                    </ul>
                                    <div className="price-btn">
                                        {estadoLogin ?
                                            <button className="button" type="button"
                                                    onClick={() => handlePlataforma()}>SUBE TU SOLUCIÓN
                                            </button> :
                                            <button className="button" type="button"
                                                    onClick={() => handleModal(2)}>SUBE TU SOLUCIÓN
                                            </button>
                                        }
                                    </div>
                                    <div className="theme-color" style={{marginTop: '14px'}}>
                                        <p>* Sin costo para startups previa validación</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pricing */}


                        {/* Start Single Pricing */}
                        <div className=" col-md-6 col-12 pricing-column mt--40" data-aos="fade-left"
                             data-aos-duration="2000">
                            <div className="single-price-package">
                                <div className="price-title">
                                    <p style={{fontSize: '1.5rem', color: '#ffff', fontWeight: 800}}>LANZA TU
                                        LLAMADO<br/> AL ECOSISTEMA</p>
                                </div>
                                <div className="price-list">
                                    <ul>
                                        <li>ACTIVA UN SCOUTING</li>
                                        <li>DESCUBRE SOLUCIONES
                                            TECNOLÓGICAS EN LATAM
                                        </li>
                                        <li>CONECTA DE FORMA ÁGIL</li>
                                        <li>DIGITALIZA TU NEGOCIO</li>
                                    </ul>
                                    <div className="price-btn">
                                        <button className="button" type="button" onClick={() => handleModal(3)}>MÁS
                                            INFORMACIÓN
                                        </button>
                                    </div>
                                    <div className="theme-color" style={{marginTop: '45px'}}>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pricing */}

                        {

                            idModal === 2 || idModal === 1 ?
                                <Modal isOpen={modal} toggle={handleModal}>
                                    <div style={{textAlign: 'end', margin: '13px'}}>
                                        <IconButton aria-label="fingerprint" color="secondary" onClick={() =>setmodal(false)}>
                                            <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                                        </IconButton>
                                    </div>
                                    <ModalBody>
                                        <RegistrarCuenta abrirModal={() => setmodal(true)}/>
                                    </ModalBody>
                                </Modal> :
                                idModal === 3 ?
                                    <Modal isOpen={modal} toggle={handleModal} className="modalBlog">
                                        <div style={{textAlign: 'end', margin: '10px'}}>
                                            <IconButton aria-label="fingerprint" color="secondary" onClick={() => setmodal(false)}>
                                                <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                                            </IconButton>
                                        </div>
                                        <ModalBody>
                                            <FormularioEmpresa handdleModal={() => handleModal()}/>
                                        </ModalBody>
                                    </Modal>
                                    :null
                        }
                        { redirect ? (<Redirect push to="/micuenta"/>) : null }
                    </div>
                </div>
            </div>

        </div>
    );
});

export default Pricing;













